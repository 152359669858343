import React, { useEffect, useRef, useState } from 'react'
import './landing.css'
import { toast, ToastContainer } from 'react-toastify';
import Typewriter from 'typewriter-effect';
import axios from "axios";
import { BsStars } from "react-icons/bs";

import { FaArrowRight } from "react-icons/fa";
import PuffLoader from "react-spinners/PuffLoader";

const Landing = () => {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const override = {
    display: "block",
    margin: "0",
    borderColor: "white",
    width: "25px",
    height: "25px",
    padding: "0"
  };

  const textRef = useRef(null);
  const letters = "abcdefghijklmnopqrstuvwxyz";
  let interval = null;
  // const [waitlistEmail, setWaitlistEmail] = useState('')
  const [contractAddress, setContractAddress] = useState('')

  const [selectedChain, setSelectedChain] = useState(null);
  const [chainID, setChainID] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(false);
  // const [addressValue, setAddressValue] = useState("");
  const addressRef = useRef();
  const [ip, setIP] = useState("");
  const [scanLoading, setScanLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [docUrl, setDocUrl] = useState(false);

  useEffect(() => {
    console.log(chainID)
  }, [chainID])


  const matrixEffect = () => {
    let iteration = 0;
  
    clearInterval(interval);
    
    interval = setInterval(() => {
      textRef.current.innerText =  textRef.current.innerText
        .split("")
        .map((letter, index) => {
          if(index < iteration) {
            return textRef.current.dataset.value[index];
          }
        
          return letters[Math.floor(Math.random() * 26)]
        })
        .join("");
      
      if(iteration >= textRef.current.dataset.value.length){ 
        clearInterval(interval);
      }
      
      iteration += 1 / 3;
    }, 30);
  }

  // const contactRequest = async () => {
  //   try{
  //       const url = '/v1/waitlist/create';
  //       const { data } = await axiosInstance.post(
  //           url,
  //           {
  //               email: waitlistEmail
  //           },
  //           {
  //               headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.co.uk" : "*")}
  //           }
  //       );
  //       toast.success("We'll be in touch with you soon!")
  //   }
  //   catch(error){
  //     toast.error("Something went wrong...")
  //   }
  // }

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  const scanSampleAddress = async (e) => {
    console.log("[+] SCAN FILE INVOKED.")
    console.log(contractAddress)
    console.log(chainID)
    if (contractAddress != ""){
      const link = '/v1/users/scanAddressSample';

      await getIP();

      try{
        toast.info("Scan Started");
        setScanLoading(true);
        const resp = await axiosInstance.post(
            link,
            {
              "address": contractAddress,
              "chain": chainID,
              "ip": ip
            }
        );
        toast.success("Scan Sucessful");
        setDocUrl(resp.data.scan.docUrl);
        setScanLoading(false);
      }catch(error){
        console.log(error)
        if(error.response.data.message){toast.error(error.response.data.message)}else{toast.error("Scan Failed.")}
        // toast.error(error);
        setScanLoading(false);
      }
    } else{
      toast.error("You must paste an EVM address before scanning.")
    }
  }

  const handleRedirect = () => {
    if(docUrl){
      window.open(docUrl);
      window.location.reload();
    }
  }

  return (
    <div className='landing2-containter'>
      <ToastContainer />
      <div className='landing2-header'>
        <h1>Automating<br/>
          <span data-value="Smart Contract Audits" ref={textRef} onMouseEnter={matrixEffect}>Smart Contract Audits</span><br/>
          With AI <BsStars style={{ color: '#3d59d8', width: '0.8em' }}/>
        </h1>
        <img className='mobile-only' src={require('./chain.png')} style={{width: '80vw'}}/>
        {/* <p>
          Satisfy <span>ISO 27001</span> compliance requirements and meet security evaluations from vendors for web, mobile apps, APIs, and external network infrastructure.
        </p> */}
        <p>
          Introducing <span>CodeHound</span>, our <span>AI enhanced</span> smart contract auditing tool, built to amplify the security of your smart contracts and blockchain projects.
        </p>
      </div>
      <div className='landing2-cta'>
        {/* <input type='text' placeholder='Enter your email' value={waitlistEmail} onChange={event => setWaitlistEmail(event.target.value)} />
        <button className='cta-flex' onClick={() => contactRequest()}>Join Waitlist</button> */}
        <input type='text' placeholder='Smart Contract Address...' value={contractAddress} onChange={event => setContractAddress(event.target.value)} />
        <div>
          <select onChange={e => setChainID(e.target.value)}>
            <option value="mainnet">Ethereum Mainnet</option>
            <option value="sepolia">Ethereum Sepolia</option>
            <option value="bsc">BNB Mainnet</option>
            <option value="testnet.bsc">BNB Testnet</option>
            <option value="base">Base Mainnet</option>
            <option value="poly">Polygon Mainnet</option>
            <option value="polyzk">Polygon zkEVM</option>
            <option value="arbi">Arbitrum Mainnet</option>
            <option value="testnet.arbi">Arbitrum Testnet</option>
            <option value="ftm">Fantom Mainnet</option>
          </select>

          <button className={`cta-flex ${docUrl ? 'scan-complete' : ''}`} onClick={(e) => {docUrl ? handleRedirect(e) : (contractAddress ? scanSampleAddress() : toast.error("You must paste an EVM address before scanning."))}}><p>{scanLoading ? "Scanning..." : ( docUrl ? "Open" : "Run Scan")}</p>
                {
                  scanLoading ? 
                  <PuffLoader
                    color={color}
                    loading={scanLoading}
                    cssOverride={override}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                : null
                }
                {docUrl ? <FaArrowRight /> : null}
          </button>
        </div>

      </div>
      <div className='landing2-footer'>
        <p>Certified by some of the worlds most innovative companies</p>
        <ul>
            <li><img alt='elearn' src={ require('../../usage/assets/logos/ELS.svg').default } /></li>
            <li><img alt='offensive security' src={ require('../../usage/assets/logos/offsec.svg').default } /></li>
            <li><img alt='Hack The Box' style={{width:'150px'}} src={ require('../../usage/assets/logos/HTB.svg').default } /></li>
            <li><img alt='Certified' src={ require('../../usage/assets/logos/CEH.svg').default } /></li>
        </ul>
      </div>
    </div>
  )
}

export default Landing